import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../Redux/Store/Store";
import Menu from "@mui/material/Menu";
import React, { useContext, useEffect } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import profile from "../../Assets/menu.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../Constants/FireBaseConfig";
import { name, sideMenu } from "../../Redux/Reducers/StaticReducers";
import LeftArrow from "../../Assets/left.png";
import rightArrow from "../../Assets/right.png";
import { useProSidebar } from "react-pro-sidebar";
import { ContextState } from "../../App";
import Logo from "../../Assets/zenlogo.png";
import WhiteLogo from "../../Assets/whitelogo.png";
import { SendAnalytics } from "../../Utils/Helper";


const Header = () => {
  const heaaderName = useSelector((state: RootState) => state.static.name);
  const sidebar = useSelector((state: RootState) => state.static.sideMenu);
  const context = useContext(ContextState);

  useEffect(() => {
    console.log("context in header", context)
    SendAnalytics()
  }, [])
  const handleClickOpenTo = (state: any) => {
    state.close();
  };
  const navigate = useNavigate();

  async function logout() {
    await signOut(auth)
      .then(function () {
        sessionStorage.removeItem("user_tracer_client");
        navigate("/login");
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
  const settings = (state: any) => {
    navigate("/change-theme");
    dispatch(name("Change Theme"));
    handleClickOpenTo(state);
  };
  const userData: any = JSON.parse(
    sessionStorage.getItem("tracer_client_basic_details") || "[]"
  );
  const user: any = JSON.parse(
    sessionStorage.getItem("tracer_client_profile_details") || "[]"
  );
  const dispatch = useDispatch();
  // const { collapseSidebar, collapsed } = useProSidebar();
  // useEffect(() => {
  //   dispatch(sideMenu(collapsed));
  // }, [collapsed]);
  const nameHeader = userData?.email?.substring(
    0,
    userData?.email?.indexOf("@")
  );
  const navigateHome = () => {
    navigate("/home");
    dispatch(name("Home"));
  };
  // const imageClick = () => {
  //   collapseSidebar(!collapsed);
  // };
  return (
    <div>
      <header>
        <div
          style={{
            height: "50px",
            // display: "grid",
            // alignItems: "center",
            // position: "relative",
            // fontWeight:"bold",
            // fontSize:"28px",
            width: !sidebar ? "150px" : "80px",
            cursor: "pointer",
            color: context.userDetails.invertColor ? "#FFFFFF" : context.userDetails.color,
            textAlign: "center",
            background: context.userDetails.invertColor ? context.userDetails.color : "#F4F5FA"
          }}
          onClick={navigateHome}>
          <img src={Logo} onClick={navigateHome} className="headerlogo" />
        </div>
        {/* <img
        src={sidebar ? rightArrow : LeftArrow}
        className={sidebar ? "rightArrow" : "leftArrow"}
        onClick={imageClick}
      /> */}
        <div className="title text-center ">{heaaderName}</div>
        <div className="profile__container fixed">
          <div className="row">
            <p className="font-semibold col-6 welcomeText opacity-80">Welcome!</p>
            <p className="text-pink-600 col-6 welcomeText">{context?.userName ? context?.userName : user.name}</p>
          </div>
          <div className="ms-3 headerPopup">
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState: any) => (
                <React.Fragment>
                  {/* <img
                  className="profileIcon cursor-pointer"
                  {...bindTrigger(popupState)}
                  src={profile}
                  alt=""
                /> */}
                  <Menu {...bindMenu(popupState)}>
                    <div className="menuItemsdiv text-center">
                      <p
                        onClick={() => settings(popupState)}
                        className="cursor-pointer"
                      >
                        Change Theme
                      </p>
                      <p
                        onClick={() => handleClickOpenTo(popupState)}
                        className="cursor-pointer mt-2"
                      >
                        Profile
                      </p>
                      <p onClick={logout} className="cursor-pointer mt-2">
                        Logout
                      </p>
                    </div>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </div>
        </div>
      </header>
    </div >
  );
};

export default Header;
