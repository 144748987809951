export class ApiEndPoints {
    static HomeList = "todos/1";
    static Register = "accounts";
    static DashboardCardCount = "accounts/container-stats"
    static DashboardChartData = "accounts/containers/stats-by-site"
    static ContainerSheets ="containers/import/sheets"
    static ContainerCsv ="containers/import/csv"
    static getProfile = "user"
    static getUpdates = "accounts/containers/updates"
    static getLineChat = "reports/created-history"
    static getEtaChart = "reports/eta-chart"
    static lfdEtaChart = "reports/lfd-chart"
    static userId = "auth/user"
    static etaToday = "reports/containers-by-eta"
    static lfdToday = "reports/last-free-day"
    static siteUpdates = "accounts/sites/status"

  }
  