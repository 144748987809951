import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import type { FetchArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { toast } from "react-toastify";
import { accessToken, loader } from "./StaticReducers";
import { auth } from "../../Constants/FireBaseConfig";
import { RootState } from "../Store/Store";
import { Mutex } from 'async-mutex'
import { useDispatch } from "react-redux";

const hostUrl = window.location.port !== ""
  ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
  : window.location.protocol + "//" + window.location.hostname;

// export const BASE_URL = hostUrl + "/api/v1/"
// export const BASE_URL = "https://sandbox.zentms.com/api/v1/"
// export const BASE_URL = "http://localhost:8000/api/v1/"
//export const BASE_URL = "https://sandbox.zentms.com/api/v1/"
const baseQuery = fetchBaseQuery({
  // baseUrl: BASE_URL,
   baseUrl: hostUrl + "/api/v1/",
  //baseUrl: "http://localhost:8000/api/v1/",
  // baseUrl: "https://sandbox.zentms.com/api/v1/",
  // baseUrl: "https://track.zentms.com/api/v1/",
  // baseUrl: "https://dev.zentms.com/api/v1/",
  // baseUrl: "http://localhost:8000/api/v1/",
  //baseUrl: "https://dev.zentms.com/api/v1/",

  prepareHeaders: async (headers, { getState }) => {
    let newToken = await auth.currentUser.getIdToken()
    headers.set("Content-type", "application/json");
    headers.set("Authorization", "Bearer " + newToken);
    return headers;
  },
})

//used for token handle and common response and error
const mutex = new Mutex()

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    api.dispatch(loader(false))

    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    const err: any = result.error?.data;
    if (result) {
      api.dispatch(loader(false))
    }
    if (result?.error?.status === 401) {

      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          auth.currentUser.getIdToken(true)
            .then(async function (idToken: any) {
              // idToken is the new accessToken
              api.dispatch(accessToken(idToken))
              sessionStorage.setItem(
                "user_tracer_client",
                JSON.stringify(idToken)
              );
              // excute the unauthorized request
              result = await baseQuery(args, api, extraOptions)
            }).catch(function (error: any) {

            });

        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }

    } else if (result?.error?.status === 400) {
      toast.error(err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return result
  },
  {
    maxRetries: 0,
  }
);

export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ["Credentials", "Containers", "Accounts"],
  endpoints: () => ({}),
});
