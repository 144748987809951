import { signOut } from "firebase/auth";
import { auth } from "../Constants/FireBaseConfig";
import ReactGA from 'react-ga';

export const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: string, orderBy: string) => {

  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (
  array: any[],
  comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any }
) => {
  const stabilizedThis = Array.isArray(array) ? array?.map((el: any, index: any) => [el, index] ) : [];
  stabilizedThis?.sort((a: number[], b: number[]) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el: any[]) => el[0]);
};


export const logout = () => {
  signOut(auth).then(function () {
    sessionStorage.removeItem("user_tracer_client");
    window.location.href = "/login";
  }).catch(function (error: any) {
    console.log(error)
  });
}

export const LocalStoreValue = () => {
  let data = JSON.parse(localStorage.getItem("rememberMe_tracer_ZenTms") || "{}");
  return data;
};

export const SendAnalytics = () => {
  console.log("Sending analytics for ", window.location.pathname)
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
}
