import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { baseApi } from '../Reducers/BaseQuery'
import { motiveApi } from '../Reducers/MotiveReducer'
import StaticReducers from '../Reducers/StaticReducers'

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [motiveApi.reducerPath]: motiveApi.reducer,
    static: StaticReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware)
      .concat(motiveApi.middleware),
})

setupListeners(store.dispatch)
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;