//  typescript react component that displays a list of vehicles and their locations on a map

import React, { useEffect, useState, useContext } from "react";
import { useGetVehicleLocationsQuery, useLazySyncVehicleLocationsQuery, useGetSamsaraVehicleLocationsQuery } from "../../Redux/Reducers/EldReducer";
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";
// import icon from "../../Assets/icon.png"
import icon from "./icon.png";
import icon2 from "./icon2.png";

import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import { getDisplayName } from "@mui/utils";
import L from "leaflet";
import Loader from "../../Components/Loader/Loader";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ContextState } from '../../App';
import { toast } from "react-toastify";
import { Timezone, DateField } from '../../Constants/Utils';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useLocation } from "react-router-dom";






const API_KEY = "AIzaSyCIJ6x_WFi3UMf9HnNVon5afLQqj1Izxmw"
const VehicleLocation = () => {
    const context = useContext(ContextState);
    const location = useLocation();
    //console.log("Driver Name is", location.state.driver);
    let tz = context.userDetails.timeZone;
    if (!tz) {
        tz = "America/New_York"
    };
    const [syncVehicleLocations] = useLazySyncVehicleLocationsQuery();
    //38.60401666654899, -96.82175605173475
    const [latitude, setLatitude] = useState(38.604);
    const [longitude, setLongitude] = useState(-96.821);
    const [filtered, setFiltered] = useState<any>([]);
    const [locationList, setLocationList] = useState<any>([]);
    const [displayName, setDisplayName] = useState("Hello");
    const { data: locations } = useGetVehicleLocationsQuery({});
    const [showLabel, setShowLabel] = useState(true);
    const [hideDriverLess, setHideDriverLess] = useState(true);
    const [showActiveDrivers, setShowActiveDrivers] = useState(location.state ? false : true);
    const [group, setGroup] = useState<any>([]);
    const [lastUpdated, setLastUpdated] = useState('');
    const [hideOlder, setHideOlder] = useState(location.state ? false : true);
    const [disableChkbx, setDisableChkbx] = useState(false);
    const { data: samsLocations, isLoading, isFetching, isError, error, refetch } = useGetVehicleLocationsQuery(null, { pollingInterval: 300000, });
    const [searchTerm, setSearchTerm] = useState<string | undefined>(location?.state?.driver);
    const [currentZoom, setCurrentZoom] = useState(15);
    const [currentBounds, setCurrentBounds] = useState<string>('');
    const [isFirstLoad, setIsFirstLoad] = useState(true);


    function MapView({ lat, lng, markers }: { lat: any, lng: any, markers: any }) {
        let map = useMap();
        // map.setView([lat, lng], map.getZoom());
        if (markers.length > 0) {
            var leafletMap = L.featureGroup(markers)

            const bounds = leafletMap.getBounds()
            const boundsString = bounds.toBBoxString()
            console.log('bounds = ', boundsString, ' current bounds = ', currentBounds)
            if (currentBounds !== boundsString) {
                setCurrentBounds(boundsString)
                map.fitBounds(leafletMap.getBounds());
            }

            let zoomValue = map.getZoom()
            console.log("zoomValue = ", zoomValue, "currentZoom ", currentZoom)
            if (zoomValue != currentZoom) {
                if (zoomValue > 13) {
                    zoomValue = 13
                }
                console.log("Updating zoomValue = ", zoomValue)
                setCurrentZoom(zoomValue)
                map.setZoom(zoomValue)
            }
        }


        //Sets geographical center and zoom for the view of the map
        return null;
    }
    const customIcon = new L.Icon({
        iconUrl: icon,
        iconSize: [25, 35],
        iconAnchor: [5, 30]
    });
    const newIcon = new L.Icon({
        iconUrl: icon2,
        iconSize: [25, 35],
        iconAnchor: [5, 30]
    });

    useEffect(() => {
        let group = []
        if (samsLocations) {
            setIsFirstLoad(false)
            //console.log(locations);
            console.log("updating lcoations")
            let valid_locations = samsLocations.filter((location: any) => {
                // Check if time is within a day
                // convert the time string to a date object
                const location_time = new Date(location?.time).getTime()
                const current_time = new Date().getTime()
                // console.log(location.vehicle?.driver?.name, ":", location.vehicle?.name, "location time = ", location_time, " current time = ", current_time, " diff = ", current_time - location_time)
                const is_valid = hideOlder ? location?.latitude && location?.longitude && (current_time - location_time) < 86400000 : location?.latitude && location?.longitude
                return is_valid
            });
            console.log(valid_locations);

            if (showActiveDrivers) {
                valid_locations = valid_locations.filter((location: any) => {
                    return location.vehicle?.driver?.status === 'active'
                })
            }

            console.log("searchTerm = ", searchTerm)
            if (searchTerm && searchTerm.length > 0) {
                valid_locations = valid_locations.filter((location: any) => {
                    return (location.vehicle?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || location.vehicle?.driver?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                })
            }

            // console.log("filtered list = ", valid_locations)
            setFiltered(valid_locations)
            setLocationList(valid_locations)
            const today = new Date();
            console.log("Setting last updated to  ", Timezone({ date: today, timezone: tz }))
            setLastUpdated(Timezone({ date: today, timezone: tz }));
        }


    }, [locations, samsLocations, hideOlder, searchTerm, showActiveDrivers]);


    useEffect(() => {
        if (filtered.length > 0) {
            console.log('Updating map filtered = ', filtered)
            const { latitude, longitude } = filtered[0]
            setLatitude(latitude)
            setLongitude(longitude)
            let group = []
            for (let i = 0; i < filtered.length; i++) {
                const { latitude, longitude } = filtered[i]
                // console.log('latitude = ', latitude, ' longitude = ', longitude)
                group.push(L.marker([latitude, longitude]))
            }
            // console.log('group = ', group)
            setGroup(group)
        }
    }, [filtered]);

    const updateLocation = () => {
        refetch();
        const today = new Date();
        setLastUpdated(Timezone({ date: today, timezone: tz }));
    }

    const handleLabelBtn = () => {
        setShowLabel(current => !current)
    }

    const handleDriverBtn = (checked: boolean) => {
        setHideDriverLess(checked)
        if (checked) {
            const list = filtered.filter((location: any) => location.vehicle?.driver)
            setFiltered(list)
            setLocationList(list)
        } else {
            if (locations) {
                //console.log(locations);
                const valid_locations = locations.filter((location: any) => location.latitude && location.longitude);
                //console.log(valid_locations);
                if (valid_locations.length > 0) {
                    setLatitude(valid_locations[0].latitude);
                    setLongitude(valid_locations[0].longitude);
                }
                setFiltered(valid_locations)
                setLocationList(valid_locations)
            }
        }

    }

    //console.log("Active Drivers : ", showActiveDrivers)

    const handleActiveDrivers = (checked: boolean) => {
        setShowActiveDrivers(checked)
        if (checked) {
            setDisableChkbx(false)
            // const list = filtered.filter((location: any) => location.vehicle?.driver?.status === 'active')
            // setFiltered(list)
            // setLocationList(list)
        } else {
            setDisableChkbx(true)

            // if (samsLocations) {
            //     //console.log(locations);
            //     const valid_locations = samsLocations.filter((location: any) => location.latitude && location.longitude);
            //     //console.log(valid_locations);
            //     if (valid_locations.length > 0) {
            //         setLatitude(valid_locations[0].latitude);
            //         setLongitude(valid_locations[0].longitude);
            //     }
            //     setFiltered(valid_locations)
            //     setLocationList(valid_locations)
            // }
        }
    }

    const handleHideOlder = (checked: boolean) => {
        setHideOlder(checked)
    }


    const label = { inputProps: { 'aria-label': 'Show All' } };
    const searchChange = (event: any) => {
        const search = event.target.value;
        console.log("search = ", search)
        setSearchTerm(search)
        const list = locationList.filter((location: any) => {
            return location.vehicle?.driver?.name.toLowerCase().includes(search.toLowerCase())
        })
        console.log('Setting filtered list = ', list)
        setFiltered(list)
    }

    const handleOnSync = () => {
        syncVehicleLocations()
        toast.warning("Refresh After Some Time For Updates")
    }

    console.log("isFirstLoad = ", isFirstLoad)

    return (

        <div>
            <div>
                <h2>Last Updated : {lastUpdated} </h2>
            </div>
            <div style={{
                borderRadius: "5px", borderColor: "red", borderWidth: "1px", borderStyle: "solid",
                height: "70vh", width: "70vw", display: "flex", justifyContent: "center", alignItems: "center"
            }}>

                <MapContainer
                    // className="map"
                    style={{ height: "100%", width: "100%" }}
                    center={[latitude, longitude]}
                    zoom={5}
                    scrollWheelZoom={true}
                >
                    {/* <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> 
        contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> */}
                    <ReactLeafletGoogleLayer apiKey={API_KEY} type={'terrain'} />

                    {filtered.map((location: any) => {
                        const { latitude, longitude, portal_vehicle_id, vehicle, time } = location;
                        const name = vehicle?.name ? vehicle?.name : vehicle?.license_plate_number;
                        return (
                            <Marker icon={customIcon} position={[latitude, longitude]} title={Timezone({ date: time, timezone: tz })} alt={portal_vehicle_id}>
                                {showLabel ? <Tooltip direction="right" offset={[10, -15]} opacity={1} permanent>{vehicle?.driver ? vehicle?.driver?.name : vehicle?.license_plate_number} {name}</Tooltip> : null}
                                <Popup>{portal_vehicle_id}</Popup>
                            </Marker>
                        )

                    })}

                    <MapView lat={latitude} lng={longitude} markers={group} />
                </MapContainer>


            </div>
            <div className="pd-3 m-3">

            </div>
            <div>
                <Button className="pd-3" variant="contained" style={{ backgroundColor: context.userDetails.color }} sx={{ borderRadius: 50, marginRight: 1 }} onClick={updateLocation}  >Update Location</Button>
                <Button
                    variant="contained"
                    onClick={handleOnSync}
                    style={{ backgroundColor: context.userDetails.color }}
                    sx={{ borderRadius: 50, marginRight: 4 }}>
                    Sync
                </Button>
                {/* <Button variant="contained" color="primary" sx={{ borderRadius: 50, marginRight: 1, marginLeft: 1 }} onClick={handleLabelBtn} >Show Labels</Button> */}
                {/* <CheckBox variant="contained" color="primary" sx={{ borderRadius: 50, marginRight: 1, marginLeft: 1 }} onClick={handleDriverBtn} >Show with Drivers</CheckBox> */}
                <input
                    type={"search"}
                    className="mt-3 ml-3 mr-3 h-10 border-2 p-3"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={searchChange}
                />
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox defaultChecked onChange={(e, checked) =>
                            setShowLabel(current => !current)
                        } />}
                        label="Show Labels" />
                    <FormControlLabel control={<Checkbox defaultChecked={showActiveDrivers} onChange={(e, checked) =>
                        handleActiveDrivers(checked)
                    } />}
                        label="Hide Inactive Drivers" />
                    <FormControlLabel disabled={disableChkbx} control={<Checkbox defaultChecked={hideOlder} onChange={(e, checked) =>
                        handleHideOlder(checked)
                    } />}
                        label="Hide Older than One Day" />
                </FormGroup>

            </div>
            {isFirstLoad && (isLoading || isFetching) ? (
                <Loader />
            ) : null}
        </div >
    )

}

export default VehicleLocation;

