import React, { useEffect, useState, useContext } from 'react'
import { useGetVehicleDataQuery, useLazySyncVehiclesQuery} from '../../Redux/Reducers/EldReducer'
import { DataGrid } from '@mui/x-data-grid';
import Loader from '../../Components/Loader/Loader';
import { ContextState } from '../../App';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";

function VehiclesList() {
  const context = useContext(ContextState);
  const { data: vehicleData, isLoading, refetch } = useGetVehicleDataQuery();
  const[syncVehicles] = useLazySyncVehiclesQuery()

  const [vehicles, setVehicles] = useState([])

  useEffect(() => {
    if (vehicleData) {
      setVehicles(vehicleData)
    }
  }, [vehicleData])

  //console.log("vehicles",vehicles);

  if(isLoading){
    return(
      <Loader />
    )
  }

  const columns = [
    {
      field: 'licNo',
      headerName: 'License Plate Number',
      minWidth: 160,
    },
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 140,
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 100,
    },
    {
      field: 'vin',
      headerName: 'VIN',
      minWidth: 200,
    },
    {
      field: 'prtVehId',
      headerName: 'Port Vehicle ID',
      minWidth: 180,
    },

  ]

  const rows = vehicles?.map((veh, i) => {
    return {
      intId: i + 1,
      prtVehId: veh.portal_vehicle_id,
      make: veh.make,
      model: veh.model,
      vin: veh.vin,
      licNo: veh.license_plate_number,
    }
  })

  const searchChange = event => {
    let datas = vehicleData?.filter(res => {
      if (
        res.portal_vehicle_id?.toString().toLowerCase()?.includes(event.target.value.toLowerCase().trim()) ||
        res.make?.toLowerCase()?.includes(event.target.value.toLowerCase().trim()) ||
        res.model?.toLowerCase()?.includes(event.target.value.toLowerCase().trim()) ||
        res.vin?.toLowerCase()?.includes(event.target.value.toLowerCase().trim()) ||
        res.license_plate_number?.toLowerCase()?.includes(event.target.value.toLowerCase().trim())
      ) {
        return res
      }
    })
    setVehicles(datas)
  }

  function getRowId(row) {
    return row.intId;
  }

  const handleOnSync = () => {
    syncVehicles()
    refetch()
    toast.warning("Refresh After Some Time For Updates")
  }

  return (
    <>
    <div className="d-flex">
        <Button
          variant="contained"
          onClick={handleOnSync}
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginLeft: 2, marginRight: 2, }}>
          Sync
        </Button>
        <input
          type={"search"}
          className="mt-3 h-10 border-2 p-1"
          placeholder="Search here"
          onChange={searchChange}
        />
    </div>
    <br/>
    <div style={{ height: 600, width: '100%' }} >
      <DataGrid getRowId={getRowId}
        rows={rows}
        columns={columns}
        //pageSizeOptions={[100]}
        disableRowSelectionOnClick
      />
    </div>
    </>
  )
}

export default VehiclesList