import React, { useEffect, useState, useContext } from 'react'
import { useGetHOSLogsQuery, useLazyGetHosExcelQuery, useUpdateEmailMutation, useGetEmailQuery, useLazySyncHOSLogsQuery } from '../../Redux/Reducers/EldReducer';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridActionsCellItem } from '@mui/x-data-grid';
import { DateField } from '../../Constants/Utils';
import Loader from '../../Components/Loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@mui/material/Button';
import './HOSstyles.css'
import { ContextState } from '../../App';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useLocation, useNavigate } from "react-router-dom";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { toast } from "react-toastify";
import { Timezone } from '../../Constants/Utils';

const customTimeSort = (a, b) => {

  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.match(/\d+/g).map(Number);
    return hours * 60 + minutes;
  };

  const timeA = parseTime(a);
  const timeB = parseTime(b);

  return timeA - timeB;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const getDateFormat = (date) => {
  var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
  return newdate;
}

class HosFilters {
  constructor(zero = true, mini = true, search = "") {
    this.zero = zero;
    this.mini = mini;
    this.search = search;
  }
}

function HosLogs() {
  const context = useContext(ContextState);
  const { state } = useLocation();
  let tz = context.userDetails.timeZone;
  if (!tz) {
    tz = "America/New_York"
  };
  const today = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: tz,
    }),
  );
  const filters = JSON.parse(
    sessionStorage.getItem("tracer_client_hos_filters") || JSON.stringify(new HosFilters)
  );
  const start = new Date();
  const end = new Date();
  start.setDate(today.getDate() - 3);
  end.setDate(today.getDate() - 1)
  console.log('filters = ', filters)
  console.log('start = ', start, 'end = ', end)
  const [endDate, setEndDate] = useState(filters?.dates?.endDate ? new Date(filters?.dates?.endDate) : end)
  const [startDate, setStartDate] = useState(filters?.dates?.startDate ? new Date(filters?.dates?.startDate) : start)

  const [dateRange, setDateRange] = useState({ startDate: getDateFormat(startDate), endDate: getDateFormat(endDate) })
  const { data, isLoading, isFetching, refetch } = useGetHOSLogsQuery(dateRange)

  const [fetchHOSExcel, { data: file }] = useLazyGetHosExcelQuery()
  const [hosLogs, setHosLogs] = useState([])
  const [email, setEmail] = useState('')
  const [updateEmail] = useUpdateEmailMutation();
  const [arr, setArr] = useState([]);
  const [onlyZeroOnDuty, setOnlyZeroOnDuty] = useState(true);
  const [onlyMinimumHours, setOnlyMinimumHours] = useState(true);
  const navigate = useNavigate();
  const [rows, setRows] = useState([])
  const [searchString, setSearchString] = useState('')
  const [syncHOSLogs] = useLazySyncHOSLogsQuery()


  const user = JSON.parse(
    sessionStorage.getItem("tracer_client_profile_details") || "[]"
  );

  const accountId = user.account

  const { data: accData } = useGetEmailQuery(accountId)
  //console.log("ID", accData?.notification_email)
  //console.log("email", email);

  useEffect(() => {
    if (filters) {
      setOnlyMinimumHours(filters.mini)
      setOnlyZeroOnDuty(filters.zero)
      setSearchString(filters.search)
    }
  }, [])

  useEffect(() => {
    if (accData) {
      setEmail(accData?.notification_email)
    }
  }, [accData])

  useEffect(() => {
    if (data) {
      setHosLogs(Object.values(data))
    }
  }, [data])

  useEffect(() => {
    sessionStorage.setItem("tracer_client_hos_filters", JSON.stringify({ dates: { startDate: startDate, endDate: endDate }, zero: onlyZeroOnDuty, mini: onlyMinimumHours, search: searchString }));
  }, [searchString, onlyZeroOnDuty, onlyMinimumHours, startDate, endDate])

  useEffect(() => {
    console.log("hosLogs", hosLogs)
    let rows = hosLogs?.map((row, i) => {
      return {
        intId: i + 1,
        driverName: row.driver_name,
        // driverId: row.driver.portal_driver_id,
        // date: DateField(row.date),
        onDuty: row.on_duty,
        offDuty: row.off_duty,
        driving: row.driving,
        sb: row.sb,
        portalID: row.portal_driver_id,
        pcd: row.personal_conveyance,
        totalHours: (row.on_duty + row.driving + row.off_duty + row.sb + row.personal_conveyance + row.yard_move),
        shift: row.on_duty + row.driving,
        yardMove: row.yard_move,
        location: row.last_location,
        locationTime: row.last_location_time,
        status: row.driver_status,
      }
    })
    if (searchString.length > 0) {
      console.log("searchString", searchString)
      rows = rows.filter(res => {
        if (
          res.driverName?.toLowerCase()?.includes(searchString)
        ) {
          return res
        }
      })
    }

    console.log("onlyZeroOnDuty", onlyZeroOnDuty)
    if (onlyZeroOnDuty) {
      rows = rows?.filter(res => {
        console.log("res = {}", res)
        if (res.shift == 0) {
          return res
        }
      })
    }
    console.log("Filtered rows = {}", rows)

    if (onlyMinimumHours) {
      // minimum hours is start date - end date in hours

      let tz = "America/New_York"
      if (context.userDetails.timeZone) {
        tz = context.userDetails.timeZone
      }
      const now = new Date(new Date().toLocaleString("en-US", { timeZone: tz })); // timezone ex: Asia/Jerusalem
      const startDay = startDate.setHours(0, 0, 0, 0)
      let endDay = endDate.setHours(23, 59, 0, 0)
      if (endDay > now) {
        endDay = now
      }
      console.log("now = {}", now, " startDate = {}", startDay, " endDate = {}", endDay, "diff = ", endDay - startDay, " diff in seconds = ", (endDate - startDate) / 1000)
      const minimumHours = ((endDay - startDay) / 1000) * 0.90
      console.log("Filtering minimum hours : ", minimumHours)
      rows = rows?.filter(res => {
        console.log("res = {}", res)
        if (res.totalHours > minimumHours) {
          return res
        }
      })
    }
    setRows(rows)

  }, [hosLogs, searchString, onlyZeroOnDuty, onlyMinimumHours])

  const searchChange = event => {
    setSearchString(event.target.value.toLowerCase().trim())
    if (data) {
      const newArr = Object.values(data);
      let datas = newArr?.filter(res => {
        if (
          res.driver_name?.toLowerCase()?.includes(event.target.value.toLowerCase().trim())
        ) {
          return res
        }
      })
      setHosLogs(datas)
    }
  }


  useEffect(() => {
    if (file) {
      const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.xlsx';
      link.click();

      window.URL.revokeObjectURL(url);
    }
  }, [file])

  //console.log("HOS",hosLogs);

  if (isLoading || isFetching) {
    return (
      <Loader />
    )
  }
  const getHourMin = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor(time / 60) % 60
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`
  }

  function RenderLocationCell(params) {
    //console.log("Test Params", params);
    return(
      <div>
      <p>{params.value}</p>
      <p style={{fontSize: "10px", color: "black"}}>{params.value ? Timezone({ date: params.row.locationTime, timezone: context.userDetails.timeZone }) : "" }</p>
      </div>
    )
  }

  const columns = [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      type: 'actions',
      width: 60,
      getActions: (params) => [
        <GridActionsCellItem icon={<OpenInFullIcon />} onClick={() => handleExpandBtnClick(params)} label="Expand" />
      ]
    },
    {
      field: 'driverName',
      headerName: 'Driver Name',
      minWidth: 180,

    },
    {
      field: 'location',
      headerName: 'Last Location',
      minWidth: 200,
      cellClassName: 'location-cell',
      renderCell: RenderLocationCell
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
    },
    {
      field: 'portalID',
      headerName: 'Portal Driver ID',
      minWidth: 120,

    },
    {
      field: 'totalHours',
      headerName: 'Total',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'offDuty',
      headerName: 'Off Duty',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'sb',
      headerName: 'Sleeper Bed',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'pcd',
      headerName: 'Personal Conveyance',
      minWidth: 160,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'yardMove',
      headerName: 'Yard Move',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'onDuty',
      headerName: 'On Duty',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'driving',
      headerName: 'Driving',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'shift',
      headerName: 'Shift',
      minWidth: 100,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
  ]


  // useEffect(() => {
  //   let sorted = hosLogs || []
  //   if (len(searchString) > 0) {

  //   }
  //   setRows(hosLogs?.map((row, i) => {
  //     //console.log("row", row)
  //     return {
  //       intId: i + 1,
  //       driverName: row.driver_name,
  //       // driverId: row.driver.portal_driver_id,
  //       // date: DateField(row.date),
  //       onDuty: row.cumulative_on_duty_hours,
  //       offDuty: row.cumulative_off_duty_hours,
  //       portalID: row.portal_driver_id
  //     }
  //   }))
  // }, [hosLogs])


  const handleDriverBtn = checked => {
    setOnlyZeroOnDuty(checked)
    setOnlyMinimumHours(checked)
  }

  const handleMinimumHours = checked => {
    setOnlyMinimumHours(checked)
  }


  function getRowId(row) {
    //console.log('rowId', row.intId)
    return row.intId;
  }

  const fetchData = () => {
    console.log('Fetching ', startDate, endDate)
    setDateRange({ startDate: getDateFormat(startDate), endDate: getDateFormat(endDate) })
  }

  const fetchExcel = () => {
    setDateRange({ startDate: getDateFormat(startDate), endDate: getDateFormat(endDate) })
    fetchHOSExcel(dateRange)
  }

  //console.log("Files", file);

  const handleChangeEmail = event => {
    setEmail(event.target.value)
  }
  const onSave = () => {
    let formData = {
      notification_email: email
    }
    updateEmail(formData)
  }

  const handleExpandBtnClick = (params) => {
    navigate("/hos", { state: { data: params.row, dates: { startDate: startDate, endDate: endDate } } });
  };

  const handleOnSync = () => {
    syncHOSLogs({ startDate: getDateFormat(startDate), endDate: getDateFormat(endDate) })
    refetch()
    toast.warning("Refresh After Some Time For Updates")
  }

  const handleOnCellClick = (params) => {
    //console.log("Cell clicked", params);
    if (params.field == "location" && params.value != undefined) {
      navigate("/eld-vehicle-locations" ,{
        state:
        {
          driver: params.row.driverName
        }
      })
    }
  };

  return (
    <>
      <div className='flex-container'>
        <p className='ptext'>Start Date</p>
        <div className='datepicker-container'>
          <DatePicker showIcon selected={startDate} onChange={(date) => setStartDate(date)} />
        </div>
        <p className='ptext'>End Date</p>
        <div className='datepicker-container'><DatePicker showIcon selected={endDate} onChange={(date) => setEndDate(date)} /> </div>
        <Button
          variant="contained"
          onClick={() => fetchData()}
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginLeft: 2, marginRight: 2, }}>
          Submit
        </Button>
        <Button
          onClick={() => fetchExcel()}
          variant="contained"
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50 }} >
          Excel
        </Button>
        <input
          type={"search"}
          className="search-box ml-5 mr-5 h-10 border-2 p-1"
          placeholder="Search here"
          onChange={searchChange}
          value={searchString}
        />
      </div>
      <div className='btn-box'>
        <Button
          variant="contained"
          onClick={handleOnSync}
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginRight: 4 }}>
          Sync
        </Button>
        <FormControlLabel control={
          <Switch onChange={(e, checked) => handleDriverBtn(checked)} checked={onlyZeroOnDuty === true}
          />}
          label="Drivers with Zero OnDuty hours" />
        <FormControlLabel control={
          <Switch onChange={(e, checked) => handleMinimumHours(checked)} checked={onlyMinimumHours === true}
          />}
          label="Drivers with minimum total hours" />
      </div>
      <div className='datagrid-container' >
        <DataGrid getRowId={getRowId}
          // sx={{
          //   // disable cell selection style
          //   '.MuiDataGrid-cell:focus': {
          //     outline: 'none'
          //   },
          //   // pointer cursor on ALL rows
          //   '& .MuiDataGrid-row:hover': {
          //     cursor: 'pointer'
          //   }
          // }}
          sx={{
            '& .location-cell': {
              color: 'blue'
            },
            '& .location-cell:hover': {
              color: '#0072bb',
              cursor: 'pointer'
            }
          }}
          rows={rows}
          columns={columns}
          pageSizeOptions={[100]}
          slots={{ toolbar: CustomToolbar }}
          disableRowSelectionOnClick
          onCellClick={handleOnCellClick}
          initialState={{
            sorting: {
              sortModel: [{ field: 'onDuty', sort: 'desc' }],
            },
          }}
        />
      </div>
      <br />
      <div className='bottom-container'>
        <p className='ptext'>Get Daily Reports</p>
        <input
          type="text"
          onChange={handleChangeEmail}
          className="email-container h-8 border-1"
          placeholder="Email"
          value={email}
        />
        <Button
          className='email-btn'
          onClick={onSave}
          variant="contained"
          size='small'
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginLeft: 2, marginRight: 2, }}>
          Save Email
        </Button>
      </div>
    </>
  )
}

export default HosLogs;
