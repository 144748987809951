// Create a new OAuth2Handler component
// This component will handle the OAuth2 flow
// It will be used by the Credentials component

import React, { useState, useEffect } from 'react';


const OAuth2Handler = () => {

    useEffect(() => {
        // Get the URL parameters which will include the authorization code and state
        const params = window.location.search;
        const code = new URLSearchParams(params).get('code');
        console.log("code", code)

    }, []);

    return (

        <div style={{ margin: '12px' }} data-testid="popup-loading">
            Loading...
        </div>
    )
}

export default OAuth2Handler;