import React, { useEffect, useState, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useGetHOSDriverQuery, useLazyGetHOSDriverQuery, useLazySyncHOSQuery } from '../../Redux/Reducers/EldReducer';
import { useLocation } from 'react-router-dom';
import { Timezone, DateField } from '../../Constants/Utils';
import { ContextState } from '../../App';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Loader from '../../Components/Loader/Loader';

const getDateFormat = (date) => {
  var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
  return newdate;
}

function HOS() {
  const context = useContext(ContextState);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  // const [arr, setArr] = useState()
  const [rows, setRows] = useState([])
  //console.log('state.data = ', state?.data?.portalID)
  //console.log('state.DATES= ', state?.dates)
  const start = new Date(state?.dates?.startDate);
  const end = new Date(state?.dates?.endDate);
  const { data: hosData, isLoading, isFetching, refetch } = useGetHOSDriverQuery({ driverID: state?.data?.portalID , startDate: getDateFormat(start), endDate: getDateFormat(end)});
  const [hos, setHos] = useState()
  const [driver, setDriver] = useState()
  const [summaryRows, setSummaryRows] = useState([])
  const [syncHOS] = useLazySyncHOSQuery()


  const getHourMin = (totalMinutes) => {
    console.log('totalMinutes = ', totalMinutes)
    const hours = Math.floor(totalMinutes / 60 / 60);
    const minutes = Math.floor(totalMinutes / 60) % 60
    return `${hours}:${minutes.toString().padStart(2, '0')}`

  }
  useEffect(() => {
    if (hosData) {
      console.log('hostData = ', hosData)
      setHos(hosData.hos)
      if (hosData.hos.length > 0) {
        setDriver(hosData.hos[0].driver.name)
      }
      const rows = hosData.hos_logs?.hos_logs?.map((row, i) => {
        return {
          intId: i + 1,
          status: row.type,
          logStartTime: row.log_start_time,
          logEndTime: row.log_end_time
        }
      })
      const sorted = rows?.sort(function (a, b) {
        return a.logStartTime.localeCompare(b.logStartTime);
      });

      if (sorted) {
        setRows(sorted)
      }

      const summary_rows = hosData.hos.map((row, i) => {
        return {
          intId: i + 1,
          date: DateField(row.date),
          onDuty: row.on_duty_duration,
          offDuty: row.off_duty_duration,
          driving: row.driving_duration,
          sb: row.sleeper_duration,
          shift: row.driving_duration + row.on_duty_duration,
          yardMove: row.yard_move_duration


        }
      })
      setSummaryRows(summary_rows)
    }
  }, [hosData])


  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
    },
    {
      field: 'logStartTime',
      headerName: 'Log Start Time',
      minWidth: 300,
      valueFormatter: (params) => {
        return Timezone({ date: params.value, timezone: context.userDetails.timeZone })
      }

    },
    {
      field: 'logEndTime',
      headerName: 'Log End Time',
      minWidth: 300,
      valueFormatter: (params) => {
        return Timezone({ date: params.value, timezone: context.userDetails.timeZone })
      }
    }
  ]

  const summary_columns = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 200,
    },
    {
      field: 'offDuty',
      headerName: 'Off Duty Hours',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'sb',
      headerName: 'Sleeper Bed',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'onDuty',
      headerName: 'On Duty Hours',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'driving',
      headerName: 'Driving Duration',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'shift',
      headerName: 'Shift',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },
    {
      field: 'yardMove',
      headerName: 'Yard Move',
      minWidth: 200,
      valueFormatter: (params) => {
        return getHourMin(params.value)
      },
    },

  ]

  if (isLoading || isFetching) {
    return (
        <Loader />
    )
}

  function getRowId(row) {
    //console.log('rowId', row.intId)
    return row.intId;
  }

  const handleOnSync = () => {
    syncHOS()
    refetch()
    toast.warning("Refresh After Some Time For Updates")
  }

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <Button
          variant="contained"
          onClick={() => navigate("/hos-logs")}
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginLeft: 2, marginRight: 2, }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleOnSync}
          style={{ backgroundColor: context.userDetails.color }}
          sx={{ borderRadius: 50, marginLeft: 2, marginRight: 2, }}>
          Sync
        </Button>
      </div>

      <h1 class="mb-4 font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-2xl dark:text-white">
        {driver}
      </h1>

      <h1 class="mb-4 font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-xl dark:text-white">
        HOS Summary
      </h1>

      <h2 class="mb-4 font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-xl dark:text-white">
        Start Date: { DateField(start) }    End Date: { DateField(end) }
      </h2>

      <div style={{ width: '100%' }} >
        <DataGrid getRowId={getRowId}
          rows={summaryRows}
          columns={summary_columns}
          pageSizeOptions={[5]}
        />
        <div class="m-4" />
        <h1 class="mb-4 font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-xl dark:text-white">
          Today HOS Log
        </h1>
        <DataGrid getRowId={getRowId}
          rows={rows}
          columns={columns}
          pageSizeOptions={[100]}
        />
      </div>
    </div>
  )
}

export default HOS 