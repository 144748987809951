import './Loader.scss';
 
function Loader(){
    return(
        <div className='cover-spin'>
            <div className="custom-loader"></div>
        </div>
    )
}
export default Loader;
 
