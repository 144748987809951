import { ApiEndPoints } from "../../Constants/ApiEndpoints";
import { baseApi } from "./BaseQuery";

const dashboard = baseApi.injectEndpoints({
  endpoints: (build) => ({
    cardCount: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.DashboardCardCount,
      }),
    }),
    getProfile: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.getProfile,
      }),
    }),
    getUpdates: build.query<any, any>({
      query: (data) => ({
        url: ApiEndPoints.getUpdates+`?page=${data.page}&per_page=${data.perPage}`,
      }),
    }),
    chartdataApi: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.DashboardChartData,
      }),
    }),
    lineChartdataApi: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.getLineChat,
      }),
    }),
    etaChartdataApi: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.getEtaChart,
      }),
      providesTags : ['Containers']
    }),
    lfdChartdataApi: build.query<any, any>({
      query: () => ({
        url: ApiEndPoints.lfdEtaChart,
      }),
      providesTags : ['Containers']
    }),
    etaToday: build.query<any, any>({
      query: (date) => ({
        url: ApiEndPoints.etaToday+`?start_date=${date.minus}&end_date=${date.plus}`,
      }),
      providesTags : ['Containers']
    }),
    lfdToday: build.query<any, any>({
      query: (date) => ({
        url: ApiEndPoints.lfdToday+`?start_date=${date.minus}&end_date=${date.plus}`,
      }),
    }),
    siteUpdates: build.query<any, any>({
      query: (id) => ({
        url:`accounts/${id}/sites/status`,
      }),
    }),
    getAccountSetting: build.query<any, any>({
      query: (id) => ({
        url: `accounts/${id}`,
      }),
    }),
  }),
});

export const {
  useCardCountQuery,
  useGetProfileQuery,
  useGetUpdatesQuery,
  useChartdataApiQuery,
  useLineChartdataApiQuery,
  useEtaChartdataApiQuery,
  useLfdChartdataApiQuery,
  useEtaTodayQuery,
  useLfdTodayQuery,
  useSiteUpdatesQuery,
  useGetAccountSettingQuery
} = dashboard;
