import React, { useContext, useEffect, useState } from "react"
import { ContextState } from "../../App";
import "../Home/Home.scss";
import { useNavigate } from "react-router-dom";
import { useGetUpdatesQuery } from "../../Redux/Reducers/DashboardReducer";
import Pagination from "@mui/material/Pagination";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Timezone } from "../../Constants/Utils";
import "./Dashboard.scss";


const ViewUpdates = () => {
    const context = useContext(ContextState);
    const navigate = useNavigate()
    const [count, setCount] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [total, setTotal] = useState<any>(1);
    const [updates, setUpdates] = useState<string[]>([])



    const pharams = {
        page: count,
        perPage: rowPerPage
    }
    const data = useGetUpdatesQuery(pharams)
    const handleChangePage = (event: any, value: any) => {
        setCount(value);
    };
    const handleChangeCount = (e: any) => {
        setRowPerPage(e.target.value);
    };
    useEffect(() => {
        if (data && data.data?.items) {
            setUpdates(data.data?.items)
            setTotal(data.data?.total_items / data.data?.items_per_page);

        }
    }, [data])
    const renderText = (text: string) => {
        const datePattern = /from (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/;
        const match = text?.match(datePattern);
        const originalDate = match ? match[1] : null;

        const datePattern1 = /to (\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)/;
        const match1 = text?.match(datePattern1);
        const toDate = match1 ? match1[1] : null;

        let idRes = text?.replace("('", "").replace("from", "@");
        let id = idRes?.substring(0, idRes?.indexOf("@"));

        const locationPattern = /from\s(.*?)\sto\s(.*)/;
        const matchText = text?.match(locationPattern);
        const fromLocation = matchText ? matchText[1] : null;
        const toLocation = matchText ? matchText[2] : null;

        if (match || match1) {
            return (
                <div>{id} from <b style={{ color: context.userDetails.color }}>{originalDate ? Timezone(originalDate) : "-"}</b> to <b style={{ color: context.userDetails.color }}>{toDate ? Timezone(toDate) : "-"}</b></div>
            )
        } else {
            return (
                <div>{id} from <b style={{ color: context.userDetails.color }}>{fromLocation ? fromLocation : "-"}</b> to <b style={{ color: context.userDetails.color }}>{toLocation ? toLocation : "-"}</b></div>
            )
        }
    }
    const statusNavigate = (data: string) => {
        let idRes = data?.replace("('", "").replace("-", "@");
        let id = idRes?.substring(0, idRes?.indexOf("@"));
        navigate("/home/view-container/" + id, {
            state:
            {
                home: true
            }
        })
    }
    return (
        <div className="">
            <div className="viewContainer">
                <button
                    className="font-semibold text-white backBtn"
                    style={{ backgroundColor: context.userDetails.color }}
                    onClick={() => navigate("/dashboard")}
                >
                    Back
                </button>
            </div>
            <div className="mt-4">
            {
                updates?.map((res: string, i: number) => {

                    return (
                        <div className="updateStatusDiv mt-2 cursor-pointer" key={i} onClick={() => statusNavigate(res)}>
                            {renderText(res)}
                        </div>
                    )
                })
            }
            </div>
            <div className="paginations mt-3 float-right d-flex">
                <Pagination
                    count={total.toFixed()}
                    page={count}
                    onChange={handleChangePage}
                    color="primary"
                    shape="rounded"
                />
                <FormControl className="select-Studeunt-filter_pageination">
                    <Select
                        className="home-multipleSelectPage"
                        value={rowPerPage}
                        name="rowPerPage"
                        onChange={handleChangeCount}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
};

export default ViewUpdates;
