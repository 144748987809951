import { useContext } from "react";
import { ContextState } from "../App";

export const SessionStorage = () => {
  let data = JSON.parse(sessionStorage.getItem("user_tracer_client") || "{}");
  return Object.values(data).length > 0 ? true : false;
};

export const SessionStorageData = () => {
  let data = JSON.parse(sessionStorage.getItem("user_tracer_client") || "{}");
  return data;
};


// export const Timezone = (date: any) => {
//   const context = useContext(ContextState);
//   return new Date(date)?.toLocaleString("en-US",
//     {
//       timeZone: context.userDetails.timeZone,
//       hour12: false,
//       year: '2-digit',
//       month: '2-digit',
//       day: '2-digit',
//       hour: '2-digit',
//       minute: '2-digit',
//     }).replace(',','')
// }

export const Timezone = (arg: any) => {
  const { date, timezone } = arg
  let tz = timezone
  if (!tz) {
    tz = "America/New_York"
  }
  return new Date(date)?.toLocaleString("en-US",
    {
      timeZone: tz,
      // hour12: false,
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).replace(',', '')
}

export const DateField = (date: any) => {
  // convert date to string in the mm-dd-yy format
  const isoDate = new Date(date)?.toISOString()?.split('T')[0];
  const parts = isoDate?.split('-');
  const year = parts[0].slice(-2);
  const month = parts[1];
  const day = parts[2];
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;



  // const parts = isoDate?.split('-');
  // const year = parts[0];
  // const month = parts[1];
  // const day = parts[2];
  // const formattedDate = `${month}/${day}/${year}`;
  // return formattedDate;

  // return new Date(isoDate)?.toLocaleString("en-US",
  //   {
  //     timeZone: "America/New_York",
  //     year: '2-digit',
  //     month: '2-digit',
  //     day: '2-digit',
  //   }).replace(',', '')


  // convert date to string in the yy-mm-dd format
  // return new Date(date)?.toISOString()?.split('T')[0];
  //  return new Date(date)?.("en-US",
  //   {
  //     year: '2-digit',
  //     month: '2-digit',
  //     day: '2-digit',
  //   }).replace(',','')
}

export const isWithin24Hours = (givenDate: any) => {
  var currentDate: any = new Date();
  var timeDifference = givenDate - currentDate;
  var isClosedWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;
  return isClosedWithin24Hours;
}

export const isWithin48Hours = (givenDate: any) => {
  var currentDate: any = new Date();
  var timeDifference = givenDate - currentDate;
  var isClosedWithin48Hours = timeDifference <= 48 * 60 * 60 * 1000;
  return isClosedWithin48Hours;
}

export const isDateExpired = (givenDate: any) => {
  var currentDate = new Date();
  var isExpired = givenDate < currentDate;
  return isExpired;
}

export const sortAndFillMissingDates = (data: any) => {
  const datesArray = Object?.keys(data);

  datesArray.sort();

  const minDate = new Date(datesArray[0]);
  const maxDate = new Date(datesArray[datesArray?.length - 1]);

  const allDates = [];
  const currentDate = new Date(minDate);
  while (currentDate <= maxDate) {
    allDates?.push(new Date(currentDate));
    currentDate?.setDate(currentDate?.getDate() + 1);
  }

  const filledData = allDates?.reduce((acc: any, date: any) => {
    const formattedDate: any = date?.toISOString()?.split('T')[0];
    const value = data[formattedDate] || 0;
    acc[formattedDate] = value;
    return acc;
  }, {});

  return filledData;
};

export const getAccessToken = () => {
  const token = sessionStorage.getItem("user_tracer_client");

  let accessToken = "";
  if (token) {

    accessToken = JSON.parse(token);
  }
  return accessToken;
}

export const extractNestedValues = (obj: any) => {

  let status = '-'
  let lfd = undefined
  let eta = undefined
  let source_json = undefined
  let last_location = ''
  let loaded = ''
  let last_event_time = undefined
  let holds = undefined


  let data = {
    "site": obj?.site ? obj?.site.toUpperCase() : (obj?.port ? obj?.port.toUpperCase() : ''),
    "container_no": obj?.container_no,
    "terminal": obj?.terminal ? obj?.terminal : "",
    "reference_no": obj?.reference_no ? obj?.reference_no : "",
    "status": obj?.status?.status ? obj?.status?.status : "",
    "eta": obj?.status?.eta ? obj?.status?.eta : "",
    "lfd": obj?.status?.lfd ? obj?.status?.lfd : "",
    "Chassis": obj?.status?.chassis_id ? obj?.rail_status?.chassis_id : obj?.chassis_id,
    "loaded": obj?.status?.loaded ? obj?.status?.loaded : "",
    "last_location": obj?.status?.last_location ? obj?.status?.last_location : "",
    "last_event_time": obj?.status?.last_event_time ? obj?.status?.last_event_time : "",
    "last_tracked_on": obj?.last_tracked_on,
    "port_holds": JSON.stringify(obj?.status?.holds ? obj?.status?.holds : obj?.holds)?.replace('{}', '-')?.replaceAll('"', ''),
    "track_status": obj?.track_status,
    "Ship_eta": obj?.ship_status?.eta,
    "_id": obj?._id,
    "account": obj?.account,
    "created_by": obj?.created_by,
    "created_on": obj?.created_on,
    "trace": obj?.trace,
    "initial": obj?.equipment?.initial ? obj?.equipment?.initial : obj?.initial,
    "number": obj?.equipment?.number ? obj?.equipment?.number : obj?.number,
    "trace_type": obj?.trace_type,
    "source_json": source_json,
    "load_no": obj?.load_no,
    "ssl": obj?.ssl
  }
  return { ...data };
};

export const isValidDate = (dateString: any) => {
  const datePattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;
  return datePattern.test(dateString);
}

export const getSiteListLocal = () => {
  let data: any = localStorage.getItem("tracer-site0001");
  return JSON.parse(data);
}

export const getPortListLocal = () => {
  let data: any = localStorage.getItem("tracer-port0001");
  return JSON.parse(data);
}

export const getSslListLocal = () => {
  let data: any = localStorage.getItem("tracer-ssl0001");
  return JSON.parse(data);
}