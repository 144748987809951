import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
apiKey: "AIzaSyBTeZFDeKFnlCQyZ81emayFqqi4WIh97Ig",
authDomain: "ez-suite-382903.firebaseapp.com",
projectId: "ez-suite-382903",
storageBucket: "ez-suite-382903-vnlzb",
messagingSenderId: "294635197977",
appId: "1:294635197977:web:f8126f3c095b342e32bc88",
measurementId: "G-CRSFF3NX67"
};

const app = initializeApp(firebaseConfig);
export const auth:any = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);