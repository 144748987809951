import "./App.css";
import { Routes, Route } from "react-router-dom";
import {
  PrivateComponents,
  RoutesComponents,
} from "./Constants/Routes";
import PublicRoute from "./Auth/PublicRoutes";
import PrivateRoute from "./Auth/PrivateRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/Store/Store";
import { Suspense, createContext, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ContextProps } from "./Models/Types";

export const ContextState = createContext<any>({});

function App() {

  const appColor = localStorage.getItem("TRACER_ZENTMS_APP_COLOR");
  const appFont = localStorage.getItem("TRACER_ZENTMS_APP_FONT");
  const appInvert = localStorage.getItem("TRACER_ZENTMS_APP_INVERTCOLOR");
  const appTimeZone = localStorage.getItem("TRACER_ZENTMS_APP_TIMEZONE");

  const [userDetails, setUserDetails] = useState({
    color: appColor ? appColor : "#0072BB",
    fontFamily: appFont ? appFont : "Roboto, Helvetica, Arial, sans-serif",
    invertColor: appInvert === "true" ? true : false,
    timeZone: appTimeZone ? appTimeZone : "America/New_York"
  });
  const [userName, setUserName] = useState("")

  const getRoutes = () => {
    return RoutesComponents.map((prop, key) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };

  const getPrivateRoutes = () => {
    return PrivateComponents.map((prop, key) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };


  const loader = useSelector((state: RootState) => state.static.loader);
  const UpdateUserDetails = (data: ContextProps) => {
    if (data.color) {
      setUserDetails({ ...userDetails, color: data.color });
    } else if (data.fontFamily) {
      setUserDetails({ ...userDetails, fontFamily: data.fontFamily });
    } else if (data.timeZone) {
      setUserDetails({ ...userDetails, timeZone: data.timeZone });
    } else {
      setUserDetails({ ...userDetails, invertColor: data.invertColor });
    }
  };
  const theme = createTheme({
    typography: {
      fontFamily: userDetails.fontFamily,
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: userDetails.fontFamily,
          },
        },
      }
    },

  });
  const updateName = (name: string) => {
    setUserName(name)
  }
  return (
    <div style={{ fontFamily: userDetails.fontFamily }}>
      <ContextState.Provider
        value={{
          userDetails,
          UpdateUserDetails,
          userName,
          updateName
        }}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <ToastContainer autoClose={2000} />
            {loader && <Loader />}
            <Routes>
              <Route element={<PublicRoute />}>{getRoutes()}</Route>
              <Route element={<PrivateRoute />}>{getPrivateRoutes()}</Route>
            </Routes>
          </Suspense>
        </ThemeProvider>
      </ContextState.Provider>
    </div>
  );
}

export default App;
