import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/Sidebar/Sidebar";
import { SessionStorage } from "../Constants/Utils";
import { RootState } from "../Redux/Store/Store";

function PrivateRoute() {
  let isAuth = SessionStorage();
  const menuOpen = useSelector((state: RootState) => state.static.sideMenu);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname?.split("/");

  return isAuth ? (
    <>
      <Header />
      <SideBar />
      <div className={menuOpen ? "mainComponentsTrue" : "mainComponentsFalse"}>
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
}

export default PrivateRoute;
