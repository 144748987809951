import { baseApi } from "./BaseQuery";

const eldSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDriverData: builder.query({
      query: () => {
        return {
          url: "eld/drivers",
          headers: {
            'Content-Type': 'application/json',
          },

        };
      },
    }),
    getVehicleData: builder.query({
      query: () => {
        return {
          url: "eld/vehicles",
          headers: {
            'Content-Type': 'application/json',
          },

        };
      },
    }),
    getVehicleLocations: builder.query({
      query: () => {
        return {
          url: "eld/vehicle-locations",
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
    }),
    getHOSLogs: builder.query({
      query: ({ startDate, endDate }) => {
        return {
          url: "eld/hos-report?startDate=" + startDate + "&endDate=" + endDate,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
    }),
    getHosExcel: builder.query({
      query: ({ startDate, endDate }) => {
        return {
          url: "eld/export/hos-report?startDate=" + startDate + "&endDate=" + endDate,
          headers: {
            'Content-Type': 'application/json',
          },
          responseHandler: ((response) => response.blob())
        };
      }
    }),
    updateEmail: builder.mutation<any, any>({
      query: (payload) => ({
        url: '/accounts/containers/notification-email',
        method: "PUT",
        body: payload,
      })
    }),
    getEmail: builder.query({
      query: (id) => ({
        url: `/accounts/${id}`,
      }),
    }),
    getHOSDriver: builder.query({
      query: (arg) => {
        const { driverID, startDate, endDate } = arg;
        return {
          url: "eld/driver/hos-report?portalDriverId=" + driverID + "&startDate=" + startDate + "&endDate=" + endDate,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
    }),
    syncDrivers: builder.query({
      query: () => {
        return {
          url: "/eld/sync/drivers",
        };
      }
    }),
    syncVehicles: builder.query({
      query: () => {
        return {
          url: "/eld/sync/vehicles",
        };
      }
    }),
    syncVehicleLocations: builder.query<any, void>({
      query: () => {
        return {
          url: "/eld/sync/vehicle_locations",
        };
      }
    }),
    syncHOSLogs: builder.query({
      query: ({ startDate, endDate }) => {
        return {
          url: "/eld/custom/hos-sync?startDate=" + startDate + "&endDate=" + endDate,
        };
      }
    }),
    syncHOS: builder.query({
      query: () => {
        return {
          url: "/eld/sync/hours_of_services",
        };
      }
    }),
    getSamsaraVehicleLocations: builder.query({
      query: () => {
        return {
          url: "eld/vehicle-stats",
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
    })
  })
})

export const { useGetDriverDataQuery, useGetVehicleDataQuery, useGetVehicleLocationsQuery, useGetHOSLogsQuery, useLazyGetHosExcelQuery, useUpdateEmailMutation, useGetEmailQuery, useGetHOSDriverQuery, useLazyGetHOSDriverQuery, useLazySyncDriversQuery, useLazySyncVehiclesQuery, useLazySyncVehicleLocationsQuery, useLazySyncHOSLogsQuery, useLazySyncHOSQuery, useGetSamsaraVehicleLocationsQuery } = eldSlice;