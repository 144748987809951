
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import exp from 'constants'

interface AuthorizeParams {
    client_id: string,
    response_type: string,
    redirect_uri: string,
    scope: string,
}

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://api.gomotive.com/oauth/authorize',
    // crendentials: "include" will face CORS if credential is not provided
    credentials: "same-origin",
    mode: "no-cors",
    prepareHeaders: (headers) => {
        headers.set("HelloSampath", "World");
        headers.set("Access-Control-Allow-Origin", "*");
        headers.set("Sec-Fetch-Mode", "no-cors");
        return headers;
    },
});


// Define a service using a base URL and expected endpoints
export const motiveApi = createApi({
    reducerPath: 'motiveApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        authorize: builder.query<string, AuthorizeParams>({
            query: (params) => ({
                url: '/',
                params: params
            }),
        }),
    }),
})

export const { useAuthorizeQuery, useLazyAuthorizeQuery } = motiveApi