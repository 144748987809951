import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { PrivateComponents } from "../../Constants/Routes";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sideMenu } from "../../Redux/Reducers/StaticReducers";
import { ContextState } from "../../App";
import Logout from "../../Assets/logout.png";
import { signOut } from "firebase/auth";
import { auth } from "../../Constants/FireBaseConfig";

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname?.split("/");
  const { collapseSidebar, collapsed } = useProSidebar();
  const context = useContext(ContextState);
  useEffect(() => {
    dispatch(sideMenu(collapsed))
  }, [collapsed]);

  useEffect(() => {
    window.screen.width <= 1199
      ? collapseSidebar(true)
      : collapseSidebar(false);
  }, [window.screen.width]);

  function detectWindowSize() {
    window.innerWidth <= 1199 ? collapseSidebar(true) : collapseSidebar(false);
  }

  window.onresize = detectWindowSize;

  async function logout() {
    await signOut(auth)
      .then(function () {
        sessionStorage.removeItem("user_tracer_client");
        navigate("/login");
        window.location.reload()
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
  const getRoutes = () => {
    return PrivateComponents.map((prop: any, key: any) => {
      if (prop.sideBarShow) {
        return (
          <MenuItem
            component={<Link to={prop.path} />}
            key={key}
            style={{
              backgroundColor: splitLocation[1] === prop.layout && context.userDetails.invertColor ? "#FFFFFF" : splitLocation[1] === prop.layout ? context.userDetails.color : "",
              height: "40px",
              borderRadius: "10px 0px 0px 10px",
              fontWeight: "600",
              width: "100%",
              fontSize: "0.8rem",
              letterSpacing: "0.15px",
              color: context.userDetails.invertColor && splitLocation[1] === prop.layout ? "#000" : splitLocation[1] === prop.layout ? "#FFFFFF" : context.userDetails.invertColor ? "#FFFFFF" : "#000000",
              boxShadow: splitLocation[1] === prop.layout ? "0px 4px 8px -4px rgba(58, 53, 65, 0.42)" : "",
              marginLeft: "5px"
            }}
          >
            <div className="d-flex">
              <img src={prop.icon} className="icon" alt=""/>
              <p
                className={
                  splitLocation[1] === prop.layout
                    ? "sideTextLeft"
                    : "sideTextLeft"
                }
              >
                <p className={context.userDetails.invertColor ? "itemName" : "itemNameFalse"}>{prop.name}</p>{" "}
              </p>
            </div>
          </MenuItem>
        );
      }
    });
  };

  const getEldRoutes = () => {
    return PrivateComponents.map((prop: any, key: any) => {
      if (prop.isEldPage) {
        return (
          <MenuItem
            component={<Link to={prop.path} />}
            key={key}
            style={{
              backgroundColor: splitLocation[1] === prop.layout && context.userDetails.invertColor ? "#FFFFFF" : splitLocation[1] === prop.layout ? context.userDetails.color : "",
              height: "40px",
              borderRadius: "10px 0px 0px 10px",
              fontWeight: "600",
              width: "100%",
              fontSize: "0.8rem",
              letterSpacing: "0.15px",
              color: context.userDetails.invertColor && splitLocation[1] === prop.layout ? "#000" : splitLocation[1] === prop.layout ? "#FFFFFF" : context.userDetails.invertColor ? "#FFFFFF" : "#000000",
              boxShadow: splitLocation[1] === prop.layout ? "0px 4px 8px -4px rgba(58, 53, 65, 0.42)" : "",
              marginLeft: "5px",
              paddingLeft: "20px"
            }}
          >
            <div className="d-flex">
              <p className={context.userDetails.invertColor ? "itemName" : "itemNameFalse"}>{prop.name}</p>
            </div>
          </MenuItem>
        );
      }
    });
  }

  return (
    <div className="sidebar">
      <Sidebar id={!collapsed ? "sideBarMain" : "sideBarMainFalse"}  rootStyles={{
        [`.css-dip3t8`]: {
          backgroundColor: context.userDetails.invertColor ? context.userDetails.color : "#F4F5FA",
        },
      }}
      >
        <Menu
          menuItemStyles={{
            button: {
              "&:hover": {
                backgroundColor: context.userDetails.invertColor ? "#FFFFFF" : context.userDetails.color,
                backgroundImage: context.userDetails.invertColor ? "#FFFFFF" : context.userDetails.color,
                borderRadius: "0 30px 30px 0px",
                color: "black",
                height: "40px"
              },
            },


          }}
        >
          {getRoutes()}
          <SubMenu label="ELD" style={{
              height: "40px",
              borderRadius: "10px 0px 0px 10px",
              fontWeight: "600",
              width: "100%",
              fontSize: "0.8rem",
              letterSpacing: "0.15px",
              marginLeft: "5px"
            }}>
            {getEldRoutes()}
          </SubMenu>
        </Menu>
        <div className="mt-5">
          <button className={context.userDetails.invertColor ? "logoutBtnTrue" : "logoutBtnFalse"} onClick={logout}>
            <img src={Logout} className="logoutImage mt-2" alt=""/>
            {!collapsed && <p className="logoutText font-bold">Logout</p>}
          </button>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
