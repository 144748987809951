import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  sideMenu: false,
  loader:false,
  accessToken:"",
  accountId:""
};

const userSlice = createSlice({
  name: "statics",
  initialState,
  reducers: {
    name: (state, action) => {
      state.name = action.payload;
    },
    sideMenu: (state, action) => {
      state.sideMenu = action.payload;
    },
    loader:(state,action)=>{
      state.loader = action.payload;
    },
    accessToken:(state,action)=>{
      state.accessToken = action.payload;
    },
    accountId:(state,action)=>{
      state.accountId = action.payload;
    },
  },
});
export const { name ,sideMenu,loader,accessToken,accountId} = userSlice.actions;
export default userSlice.reducer;
