import { lazy } from 'react';
import OAuth2Handler from '../Pages/Credentials/OAuth2Handler';
import ViewUpdates from '../Pages/Dashboard/ViewUpdates';
import HosLogs from '../Pages/ELD/HOSLogs';
import VehicleLocation from '../Pages/ELD/VehicleLocation';
import VehiclesList from '../Pages/ELD/VehiclesList';
import HOS from '../Pages/ELD/HOS';

const Dashboards = lazy(() => import('../Pages/Dashboard/Dashboard'));
const Home = lazy(() => import('../Pages/Home/Home'));
const AddData = lazy(() => import('../Pages/AddData/AddData'));
const AddContainer = lazy(() => import('../Pages/Home/AddContainer'));
const ViewContainer = lazy(() => import('../Pages/Home/ViewContainer'));
const ViewContainerSource = lazy(() => import('../Pages/Home/ViewSource'));
const User = lazy(() => import('../Pages/User/User'));
const UserView = lazy(() => import('../Pages/User/UserView'));
const Credientials = lazy(() => import('../Pages/Credentials/Credentials'));
const ListCredientials = lazy(() => import('../Pages/Credentials/ListCredentials'));
const Reports = lazy(() => import('../Pages/Reports/Reports'));
const Settings = lazy(() => import('../Pages/Settings/Settings'));
const Theme = lazy(() => import('../Components/ChangeTheme/Theme'));
const Login = lazy(() => import('../Pages/Login/Login'));
const Signup = lazy(() => import('../Pages/SignUp/Signup'));
const ForgotPassword = lazy(() => import('../Pages/ForgotPassword/ForgotPassword'));
const ReportsLanding = lazy(() => import('../Pages/Reports/ReportsLanding'));
const Drivers = lazy(() => import('../Pages/ELD/DriversList'));
const LatLong = lazy(() => import('../Pages/ELD/LatLong'));
const ViewReceipts = lazy(() => import('../Pages/Home/ViewReceipts'));

var RoutesComponents = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "*",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
];

var PrivateComponents = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    layout: "home",
    sideBarShow: true,
    icon: require("../Assets/dashboard.png")

  },
  {
    path: "*",
    name: "Home",
    component: Home,
    layout: "home",
    sideBarShow: false,
    icon: require("../Assets/container.png")
  },
  {
    path: "/dashboard/add-data",
    name: "addData",
    component: AddData,
    sideBarShow: false,
  },
  {
    path: "/home/add-container",
    name: "addContainer",
    component: AddContainer,
    sideBarShow: false,
    layout: "home",
  },
  {
    path: "/home/view-container/:id",
    name: "viewContainer",
    component: ViewContainer,
    sideBarShow: false,
  },
  {
    path: "/home/view-container/:id/source",
    name: "viewContainer",
    component: ViewContainerSource,
    sideBarShow: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboards,
    layout: "dashboard",
    sideBarShow: true,
    icon: require("../Assets/container.png")
  },
  {
    path: "/dashboard/view-updates",
    name: "Updates",
    component: ViewUpdates,
    layout: "dashboard",
    sideBarShow: false,
  },

  {
    path: "/accounts",
    name: "User",
    component: User,
    sideBarShow: false,
  },
  {
    path: "/accounts/view",
    name: "User",
    component: UserView,
    sideBarShow: false,
  },
  {
    path: "/settings/createCredentials",
    name: "Credentials",
    component: Credientials,
    layout: "settings",
    sideBarShow: false,
  },
  {
    path: "/settings/createCredentials/edit",
    name: "Credentials",
    component: Credientials,
    layout: "settings",
    sideBarShow: false,
  },
  {
    path: "/settings/credientials",
    name: "Credentials",
    component: ListCredientials,
    layout: "settings",
    sideBarShow: false,
  },
  {
    path: "/oauth/:provider",
    name: "OauthHandler",
    component: OAuth2Handler,
    sideBarShow: false,
  },
  // {
  //   path: "/reports",
  //   name: "Demurrage",
  //   component: Reports,
  //   layout: "",
  //   sideBarShow: false,
  //   icon:require("../Assets/dem.png")
  // },
  // {
  //   path: "/reports",
  //   name: "Detention",
  //   component: Reports,
  //   layout: "",
  //   sideBarShow: false,
  //   icon:require("../Assets/det.png")
  // },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsLanding,
    layout: "reports",
    sideBarShow: true,
    icon: require("../Assets/reports.png")
  },
  {
    path: "/reports/:id",
    name: "Reports",
    component: Reports,
    layout: "reports",
    sideBarShow: false,
    icon: require("../Assets/reports.png")
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "settings",
    sideBarShow: true,
    icon: require("../Assets/settings.png")
  },
  {
    path: "/change-theme",
    name: "Change Theme",
    component: Theme,
    layout: "change-theme",
    sideBarShow: false,
  },
  {
    path: "/eld-drivers",
    name: "Drivers",
    component: Drivers,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: true,
  },
  {
    path: "/eld-vehicles",
    name: "Vehicles",
    component: VehiclesList,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: true,
  },
  {
    path: "/eld-vehicle-locations",
    name: "Locations",
    component: VehicleLocation,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: true,
  },
  {
    path: "/hos-logs",
    name: "HOS Logs",
    component: HosLogs,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: true,
  },
  {
    path: "/hos",
    name: "HOS",
    component: HOS,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: false,
  },
  {
    path: "/current-latlong",
    name: "Current LatLong",
    component: LatLong,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: true,
  },
  {
    path: "/view-receipts",
    name: "View Receipts",
    component: ViewReceipts,
    layout: "change-theme",
    sideBarShow: false,
    isEldPage: false,
  },

];


export { RoutesComponents, PrivateComponents };
